import React from "react"


const List = ({ items }) => {
  if (!items) {
    return null;
  } else { return (
      <ul>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
      </ul>
    )
  }
};


export default List
